/***
 * 奔腾数据统计模块，左侧导航数据，供 Nav.vue 组件，及其它组件使用
 * id: Nav.vue 组件中 menu 所需要的 key
 * label: Nav.vue 组件中 menu 所需要的显示的名称
 * path: 路由地址
 * children: Nav.vue 中二级菜单，目前只有二级
 * childrenPath: 右侧子路由列表。导航到该数组所包含的路由时，左侧 Nav.vue 中选中菜单保持不变
 */

const prefix = '/index/statistics/';

const skillDetailPrefix = `${prefix}skillDetail/`;
const skillRecordDetailPrefix = `${prefix}skillRecordDetail/`;
const statisticsGroupDetailPrefix = `${prefix}groupDetail/`;

const participationBigAreaPrefix = `${prefix}participation/bigArea/`;
const participationOrgPrefix = `${prefix}participation/org/`;
const participationStudentPrefix = `${prefix}participation/student/`;

const StatisticsNavs = [
  {
    id: '2', label: '参训率查询', path: '/', children: [
      {
        id: '2-1',
        label: '大区统计',
        path: `${prefix}participation/bigArea`,
        remain: true,
        childrenPath: [
          `${participationBigAreaPrefix}finish`,
          `${participationBigAreaPrefix}exam`,
          `${participationBigAreaPrefix}participate`,
        ],
      },
      {
        id: '2-2', label: '经销商统计', path: `${prefix}participation/org`, remain: true, childrenPath: [
          `${participationOrgPrefix}finish`,
          `${participationOrgPrefix}exam`,
          `${participationOrgPrefix}participate`,
        ],
      },
      {
        id: '2-3',
        label: '学员明细',
        path: `${prefix}participation/student`,
        remain: true,
        childrenPath: [
          `${participationStudentPrefix}finish`,
          `${participationStudentPrefix}exam`,
          `${participationStudentPrefix}participate`,
        ],
      },
    ],
  },
  {
    id: '1', label: '话术点检统计', path: '/', children: [
      {
        id: '1-1', label: '话术查询', path: `${prefix}skillDetail`, childrenPath: [
          `${skillDetailPrefix}area`,
          `${skillDetailPrefix}distributor`,
          `${skillDetailPrefix}student`,
          `${skillDetailPrefix}studentDetail`,
          `${skillRecordDetailPrefix}group`,
          `${skillRecordDetailPrefix}practice`,
          `${skillRecordDetailPrefix}study`,
          `${prefix}topicList`,
        ],
      },
      {
        id: '1-2', label: '检核查询', path: `${prefix}groupDetail`, childrenPath: [
          `${statisticsGroupDetailPrefix}area`,
          `${statisticsGroupDetailPrefix}distributor`,
          `${statisticsGroupDetailPrefix}student`,
          `${prefix}studentDetail`,
        ],
      },
    ],
  },
  {
    id: '3', label: '证书管理', path: '/', children: [
      {
        id: '3-1', label: '证书查询', path: `${prefix}certificate`, childrenPath: [
          `${prefix}certificate/online`,
          `${prefix}certificate/offline`,
        ],
      },
    ],
  },
  {
    id: '4', label: '课程查询', path: '/', children: [
      {
        id: '4-1', label: '学习人次查询', path: `${prefix}course/person-time`,
      },
    ],
  },
];

/***
 * 话术检点页面内 TabMenu 选项配置
 * id: 选中唯一标志
 * title: 显示文字
 * name: 刷新页面时，用于匹配当前选中项，与路由配置中 name 字段值保持一致
 * path: 路由地址
 */

  // 数据统计-话术查询
const statisticsSkillDetail = [
    {
      id: '1',
      title: '区域数据',
      name: 'StatisticsSkillArea',
      path: `${skillDetailPrefix}area`,
    },
    {
      id: '2',
      title: '经销商数据',
      name: 'StatisticsSkillDistributor',
      path: `${skillDetailPrefix}distributor`,
    },
    {
      id: '3',
      title: '学员数据',
      name: 'StatisticsSkillStudent',
      path: `${skillDetailPrefix}student`,
    },
  ];

const statisticsSkillStudentDetail = [
  {
    id: '1',
    title: '学员个人数据',
    name: 'StatisticsSkillStudentDetail',
    path: `${skillDetailPrefix}studentDetail`,
  },
];

// 已废弃
// 数据统计-话术查询-检核记录/练习记录/学习记录
const statisticsSkillStudentDataDetail = [
  {
    id: '1',
    title: '检核记录',
    name: 'StatisticsSkillStudentDataDetailGroupRecord',
    path: `${skillRecordDetailPrefix}group`,
  },
  {
    id: '2',
    title: '练习记录',
    name: 'StatisticsSkillStudentDataDetailPracticeRecord',
    path: `${skillRecordDetailPrefix}practice`,
  },
  {
    id: '3',
    title: '学习记录',
    name: 'StatisticsSkillStudentDataDetailStudyRecord',
    path: `${skillRecordDetailPrefix}study`,
  },
];

// 数据统计-检核查询
const statisticsGroupDetail = [
  {
    id: '1',
    title: '区域数据',
    name: 'StatisticsGroupArea',
    path: `${statisticsGroupDetailPrefix}area`,
  },
  {
    id: '2',
    title: '经销商数据',
    name: 'StatisticsGroupDistributor',
    path: `${statisticsGroupDetailPrefix}distributor`,
  },
  {
    id: '3',
    title: '学员数据',
    name: 'StatisticsGroupStudent',
    path: `${statisticsGroupDetailPrefix}student`,
  },
];

const statisticsSkillTopicList = [
  {
    id: '1',
    title: '查询明细表',
    name: 'StatisticsSkillTopicList',
    path: `${prefix}topicList`,
  },
];

const statisticsGroupStudentDetail = [
  {
    id: '1',
    title: '',
    name: 'StatisticsGroupStudentDetail',
    path: `${prefix}studentDetail`,
  },
];

export {
  statisticsSkillDetail,
  statisticsSkillStudentDetail,
  statisticsSkillStudentDataDetail,
  statisticsGroupDetail,
  statisticsSkillTopicList,
  statisticsGroupStudentDetail,
};

export default StatisticsNavs;
