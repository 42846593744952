import {stringify} from 'qs';
import {baseUrl} from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/JsDownload';

/* 区域数据 */

// 检核查询-区域数据
export function getGroupAreaData(params) {
  return request({
    url: '/speechskill/statistics/check/all',
    method: 'get',
    params,
  });
}

// 检核查询-区域数据-导出 excel
export function getGroupAreaDataDownload(params) {
  let url = '/speechskill/statistics/check/all';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 检核查询-区域数据岗位
export function getGroupAreaPostData(params) {
  return request({
    url: '/speechskill/statistics/check/allByPost',
    method: 'get',
    params,
  });
}

// 检核查询-区域岗位数据-导出 excel
export function getGroupAreaPostDataDownload(params) {
  let url = '/speechskill/statistics/check/allByPost';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}


/* 经销商数据 */

// 检核查询-经销商数据
export function getGroupDistributorData(params) {
  return request({
    url: '/speechskill/statistics/check/allByOrg',
    method: 'get',
    params,
  });
}

// 检核查询-经销商数据-导出 excel
export function getGroupDistributorDataDownload(params) {
  let url = '/speechskill/statistics/check/allByOrg';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 检核查询-经销商岗位数据
export function getGroupDistributorPostData(params) {
  return request({
    url: '/speechskill/statistics/check/allByOrgPost',
    method: 'get',
    params,
  });
}

// 检核查询-经销商岗位数据-导出 excel
export function getGroupDistributorPostDataDownload(params) {
  let url = '/speechskill/statistics/check/allByOrgPost';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}


/* 学员数据 */

// 检核查询-个人检核成绩
export function getGroupStudentData(params) {
  return request({
    url: '/speechskill/statistics/check/userScore',
    method: 'get',
    params,
  });
}

// 检核查询-个人检核成绩-导出 excel
export function getGroupStudentDataDownload(params) {
  let url = '/speechskill/statistics/check/userScore';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  if (params.down_type === 1) {
    return request({
      url,
      method: 'GET',
      params
    })
  }
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 检核查询-个人数据
export function getGroupStudentRecordData(params) {
  return request({
    url: '/speechskill/statistics/check/userScoreDetail',
    method: 'get',
    params,
  });
}

// 检核查询-个人数据-导出 excel
export function getGroupStudentRecordDataDownload(params) {
  let url = '/speechskill/statistics/check/userScoreDetail';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
