<template>
  <div class="ss-statistics-skill-student-detail">
    <SkillQuery :big-area-enabled="false"
                :cancel-enabled="true"
                :exam-pass-status-enabled="false"
                :job-enabled="false"
                :org-enabled="false"
                :filter-enabled="false"
                :downloading="downloading"
                @reset="onReset"
                @search="onSearch"
                @export="exportExcel"
                @cancel="back"/>

    <div class="row row-left">
      <div class="col col-left no-horizontal-padding">
        <span>学员名称：</span>
        <span>{{ studentName }}</span>
      </div>
    </div>

    <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
             row-key="id" class="table small-cell-table">
      <template slot="score" slot-scope="t">
        <span v-if="queries.skillType === 1">--</span>
        <span v-else>{{ t }}</span>
      </template>
      <template slot="action" slot-scope="text, record">
        <div class="table-cell-wrapper">
          <a-button type="link" size="small" @click="viewCompareResult(record)">查看文字对比结果</a-button>
          <a-button v-if="record.exam_video_url" type="link" size="small" @click="viewVideo(record)">查看检核视频</a-button>
          <a-button v-if="record.answer_voice_url" type="link" size="small" @click="viewAudio(record)">回听音频</a-button>
        </div>
      </template>
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>

    <AudioResult v-if="audioModalShown" :data="selectedRecord" @close="closeAudio"></AudioResult>
    <TextResult v-if="textModalShown" :data="selectedRecord" :is-study="queries.skillType === 1"
                @close="closeText"></TextResult>
    <VideoResult v-if="videoModalShown" :data="selectedRecord" @close="closeVideo"></VideoResult>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Common from '@/utils/Common';
import {getSkillStudentRecordData, getSkillStudentRecordDataDownload} from '@/api/statistics/Index';

import AudioResult from '@views/statistics/components/AudioResult';
import Pagination, {getPagination} from '@components/Pagination';
import SkillQuery from '../../components/SkillQuery';
import TextResult from '@views/statistics/components/TextResult';
import VideoResult from '@views/statistics/components/VideoResult';


const columns = [
  {
    width: '',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '',
    align: 'center',
    title: '话术题目',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '',
    align: 'center',
    title: '所属记录',
    dataIndex: 'record_num_title',
    key: 'record_num_title',
  },
  {
    width: '',
    align: 'center',
    title: '成绩',
    dataIndex: 'score',
    key: 'score',
    scopedSlots: {customRender: 'score'},
  },
  {
    width: '',
    align: 'center',
    title: '用时',
    dataIndex: 'use_time',
    key: 'use_time',
  },
  {
    width: '',
    align: 'center',
    title: '提交时间',
    dataIndex: 'finish_time_1',
    key: 'finish_time_1',
  },
  {
    width: '',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];


export default {
  name: 'StatisticsSkillStudent',
  components: {AudioResult, Pagination, SkillQuery, TextResult, VideoResult},
  computed: {
    ...mapState({
      queries: state => state.Statistics.speechSkillQuery,
    }),
  },
  data() {
    return {
      loading: false,
      downloading: false,
      columns,
      data: [],

      pagination: {...getPagination(), showSizeChanger: false},

      studentId: '',
      studentName: '',

      selectedRecord: {},
      textModalShown: false,
      videoModalShown: false,
      audioModalShown: false,
    };
  },
  created() {
    const query = this.$route?.query || {};
    this.studentId = query.uid || '';
    this.studentName = query.uname || '';

    if (this.queries?.skill && this.studentId) {
      this.onSearch();
    }
  },
  destroyed() {
  },
  methods: {
    back() {
      this.$router.replace({path: '/index/statistics/skillDetail/student'});
    },

    exportExcel() {
      const params = this.getParams(this.pagination.current, this.pagination.pageSize, true);

      if (!this.queries?.skill) {
        return this.$message.info({content: '请选择话术'});
      }

      this.downloading = true;
      getSkillStudentRecordDataDownload(params).then(() => this.downloading = false).catch(() => this.downloading = false);
    },

    onReset() {
      this.data = [];
      this.pagination = { ...getPagination(), showSizeChanger: false };
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (!this.queries?.skill) {
        return this.$message.info({content: '请选择话术'});
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      const data = await getSkillStudentRecordData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, isDown) {
      const params = {
        page: page,
        per_page: pageSize,
        userid: this.studentId,
      };

      this.queries?.startDateTime && (params.start_time = this.queries.startDateTime);
      this.queries?.endDateTime && (params.end_time = this.queries.endDateTime);
      this.queries?.skill && (params.skill_ids = this.queries.skill);
      this.queries?.skillType && (params.speech_type = this.queries.skillType);
      isDown && (params.down = 1);

      return params;
    },

    viewCompareResult(record) {
      this.selectedRecord = record || {};
      this.textModalShown = true;
    },
    viewVideo(record) {
      this.selectedRecord = record || {};
      this.videoModalShown = true;
    },
    viewAudio(record) {
      this.selectedRecord = record || {};
      this.audioModalShown = true;
    },
    closeText() {
      this.selectedRecord = {};
      this.textModalShown = false;
    },
    closeVideo() {
      this.selectedRecord = {};
      this.videoModalShown = false;
    },
    closeAudio() {
      this.selectedRecord = {};
      this.audioModalShown = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ss-statistics-skill-student-detail {
  width: 100%;
}
</style>
