<template>
  <div class="ss-statistics-group-student-data">
    <CommonTitle :titles="titles"></CommonTitle>

    <div class="row row-left mb-10">
      <div class="row row-left row-grow no-horizontal-padding bb">
        <div class="col col-left">
          <span>学员姓名：</span>
          <span>{{ studentName }}</span>
        </div>

        <div class="col col-left">
          <span>检核名称：</span>
          <span>{{ groupTitle }}</span>
        </div>
      </div>
    </div>

    <GroupQuery :org-enabled="false"
                :record-enabled="false"
                :job-enabled="false"
                :big-area-enabled="false"
                :exam-type-enabled="false"
                :group-enabled="true"
                :exam-name-enabled="false"
                :downloading="downloading"
                @reset="onReset"
                @search="onSearch"
                @export="exportExcel"
                @cancel="back"></GroupQuery>


    <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
             row-key="id" class="table small-cell-table">
      <template slot="action" slot-scope="text, record">
        <div class="table-cell-wrapper">
          <a-button type="link" size="small" @click="viewCompareResult(record)">查看文字比对结果</a-button>
          <a-button type="link" size="small" @click="viewVideoRecord(record)">查看检核视频</a-button>
        </div>
      </template>
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>


    <TextResult v-if="textModalShown" :data="selectedRecord" @close="closeText"></TextResult>
    <VideoResult v-if="videoModalShown" :data="selectedRecord" @close="closeVideo"></VideoResult>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {
  getGroupStudentRecordData,
  getGroupStudentRecordDataDownload,
} from '@/api/statistics/Index';
import Common from '@/utils/Common';

import Pagination, {getPagination} from '@components/Pagination';
import CommonTitle from '@components/CommonTitle';
import GroupQuery from '@views/statistics/components/GroupQuery';
import TextResult from '@views/statistics/components/TextResult';
import VideoResult from '@views/statistics/components/VideoResult';

const columns = [
  {
    width: '',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '',
    align: 'center',
    title: '话术题目',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '',
    align: 'center',
    title: '所属记录',
    dataIndex: 'check_record_num',
    key: 'check_record_num',
  },
  {
    width: '',
    align: 'center',
    title: '成绩(分)',
    dataIndex: 'score',
    key: 'score',
  },
  {
    width: '',
    align: 'center',
    title: '用时(秒)',
    dataIndex: 'use_time',
    key: 'use_time',
  },
  {
    width: '',
    align: 'center',
    title: '提交时间',
    dataIndex: 'finish_time_1',
    key: 'finish_time_1',
  },
  {
    width: '',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];


export default {
  name: 'StatisticsGroupStudentData',
  components: {CommonTitle, GroupQuery, Pagination, TextResult, VideoResult},
  computed: {
    ...mapState({
      groupList: state => state.Assessment.assessmentList,
      queries: state => state.Statistics.speechGroupQuery,
    }),
  },
  watch: {
    'queries.group'(id) {
      if (!this.groupList || !this.groupList.length || !id) {
        this.groupTitle = '';
        return;
      }

      const [group] = this.groupList.filter(i => +i.id === +id);
      this.groupTitle = group?.title || '';
    },
    'groupList'(list) {
      if (!list || !list.length || !this.queries.group) {
        this.groupTitle = '';
        return;
      }

      const [group] = list.filter(i => +i.id === +this.queries.group);
      this.groupTitle = group?.title || '';
    },
  },
  data() {
    return {
      titles: [{title: '话术管理'}, {title: '数据统计'}, {title: '检核查询'}],
      loading: false,
      downloading: false,
      columns,
      data: [],

      pagination: {...getPagination(), showSizeChanger: false},

      groupTitle: '',

      textModalShown: false,
      videoModalShown: false,
      selectedRecord: {},
    };
  },
  created() {
    const query = this.$route?.query || {};
    this.studentId = query.uid || '';
    this.studentName = query.uname || '';

    if (this.studentId && this.queries?.group) {
      this.onSearch();
    }
  },
  methods: {
    back() {
      this.$router.back();
    },

    exportExcel() {
      const params = this.getParams(this.pagination.current, this.pagination.pageSize, true);

      if (!this.queries?.group) {
        return this.$message.info({content: '请选择话术检核'});
      }

      this.downloading = true;
      getGroupStudentRecordDataDownload(params).then(() => this.downloading = false).catch(() => this.downloading = false);
    },

    onReset() {
      this.data = [];
      this.pagination = { ...getPagination(), showSizeChanger: false };
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (!this.queries?.group) {
        return this.$message.info({content: '请选择话术检核'});
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      if (!params.user_id) {
        return this.$message.warning({content: '没有学员 id'});
      }

      const data = await getGroupStudentRecordData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      result.forEach((item, idx) => {
        item.uid = idx + 1 + (page - 1) * pageSize;
        item.uid = 'sd-' + item.uid;
      });
      this.data = result;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, isDown) {
      const params = {
        page: page,
        per_page: pageSize,
      };

      params.user_id = this.studentId || '';
      params.check_id = this.queries?.group || '';
      this.queries?.startDateTime && (params.start_time = this.queries.startDateTime);
      this.queries?.endDateTime && (params.end_time = this.queries.endDateTime);
      isDown && (params.down = 1);

      return params;
    },


    viewCompareResult(record) {
      this.selectedRecord = record || {};
      this.textModalShown = true;
    },
    viewVideoRecord(record) {
      this.selectedRecord = record || {};
      this.videoModalShown = true;
    },
    closeText() {
      this.selectedRecord = {};
      this.textModalShown = false;
    },
    closeVideo() {
      this.selectedRecord = {};
      this.videoModalShown = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ss-statistics-group-student-data {
  .bb {
    border-bottom: 1px solid #DDDDDD;
  }
}
</style>
