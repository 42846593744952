<!--下拉选择，选择学员考试通过状态-->

<template>
  <div class="ss-select ss-select-exam-pass-status">
    <span v-if="showLabel">通过状态：</span>

    <a-select v-model="selectedId" :disabled="disabled" placeholder="请选择" class="select"
              @change="select">
      <a-select-option v-for="item of list" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>
  </div>
</template>


<script>

const list = [
  {id: 0, name: '全部'},
  {id: 1, name: '通过'},
  {id: 2, name: '未通过'},
];

export default {
  name: 'SelectExamPassStatus',
  props: {
    showLabel: {type: Boolean, default: true},
    defaultSelectedId: {type: [String, Number], default: ''},
    disabled: {type: Boolean, default: false},
  },
  watch: {
    defaultSelectedId() {
      this.setDefault();
    },
  },
  data() {
    return {
      selectedId: 0,
      list: list,
    }
  },
  created() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      if (!this.defaultSelectedId) {
        return;
      }
      this.selectedId = +this.defaultSelectedId || 0;
      this.select();
    },
    select() {
      if (!this.list || !this.list.length || this.selectedId === undefined || this.selectedId === null) {
        return;
      }
      this.$emit('select', this.selectedId || 0);
    },
  },
}
</script>

<style scoped lang="scss">
.ss-select-exam-pass-status {
  .select {
    max-width: 160px;
  }
}
</style>
