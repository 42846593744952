<template>
  <a-select v-model="selectedId"
            :mode="mode"
            :allow-clear="true"
            :auto-clear-search-value="false"
            :show-search="true"
            :disabled="disabled"
            placeholder="请选择任务"
            class="select"
            :filter-option="false"
            option-label-prop="label"
            @focus="resetSearch(!0)"
            @blur="resetSearch(!1)"
            @popupScroll="onScroll"
            @change="select"
            @search="onSearch">
    <a-select-option v-for="item of filteredOptions"
                     :key="item.id"
                     :value="item.id"
                     :label="item.title"
                     :disabled="itemDisabled(item)">
      <div class="flex space-between">
        <a-tooltip :title="item.title" class="option-title">{{ item.title }}</a-tooltip>
        <span class="flex-none">
          {{ item.course_ids.split(',').length }}门
        </span>
      </div>
    </a-select-option>
  </a-select>
</template>

<script>
import { mapState } from 'vuex';
import {
  maxAllowableCourseCount,
} from '../config';

export default {
  name: 'SelectPlan',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
    selectedCourseIds: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    mode: {
      type: String,
      default: 'default',
      validate: (i) => [
        'default',
        'multiple',
      ].includes(i),
    },
    type: {
      type: String,
      default: '',
      validator: (value) => ['course', 'exam', ''].includes(value),
    },
  },
  computed: {
    ...mapState({
      options: (state) => state.StatisticsParticipation.planList,
    }),

    itemDisabled() {
      return (item) => !this.ids.includes(
        item.id,
      ) && this.selectedCourseCount + item.course_ids.split(
        ',',
      ).length > maxAllowableCourseCount;
    },

    ids() {
      return this.mode === 'multiple' ? [
        ...(this.selectedId || []),
      ] : [
        this.selectedId,
      ];
    },

    selectedCourseCount() {
      return this.options.reduce((prev, cur) => {
        if (this.ids.includes(cur.id)) {
          return prev + cur.course_ids.split(',').length;
        }

        return prev;
      }, 0);
    },
  },
  watch: {
    value() {
      this.setDefault();
    },
    // selectedCourseIds() {
    //   this.updatePlanId();
    // },
    options() {
      this.onSearch(this.filterTxt);
    },
  },
  data() {
    return {
      selectedId: undefined,

      focused: false,
      page: 1,
      perPage: 100,
      filterTxt: '',
      filteredOptions: [],
    };
  },
  created() {
    if (!this.options?.length) {
      this.$store.dispatch(
        'StatisticsParticipation/getParticipationPlanCourse',
      ).finally(() => {
        this.setDefault();
        this.onSearch('');
        // this.updatePlanId();
      });
    } else {
      this.setDefault();
      this.onSearch('');
      // this.updatePlanId();
    }
  },
  methods: {
    resetSearch(focused) {
      this.focused = focused;
      this.onSearch('');
    },
    onSearch(value) {
      this.filterTxt = value;
      this.page = 1;
      this.getFilteredOptions();
    },

    getFilteredOptions() {
      this.filteredOptions = this.options.filter((option, index) => {
        if (this.ids.includes(+option.id)) {
          return true;
        }
        if (!this.filterTxt && index < this.page * this.perPage) {
          return true;
        }
        return this.filterTxt && option.title.indexOf(this.filterTxt) > -1;
      });
    },

    onScroll(ev) {
      const target = ev.currentTarget || ev.target || ev.srcElement;
      if (!target) {
        return;
      }

      const height = target.clientHeight || 250;
      const scrollTop = target.scrollTop || 0;
      const scrollHeight = target.scrollHeight || 250;

      if (
        scrollTop + 1.2 * height > scrollHeight &&
        this.page * this.perPage < this.options.length
      ) {
        this.loadMore();
      }
    },

    loadMore() {
      this.page += 1;
      this.getFilteredOptions();
    },

    setDefault() {
      if (!this.value) {
        this.selectedId = this.mode === 'multiple' ? [] : undefined;
        return;
      }
      this.selectedId = this.mode === 'multiple' ? (this.value || '').split(',').filter(
        (i) => !!i,
      ).map(
        (i) => +i,
      ) : (this.value ? +this.value : undefined);
      this.getFilteredOptions();
    },
    updatePlanId() {
      if (!this.selectedCourseIds) {
        this.selectedId = this.mode === 'multiple' ? [] : undefined;
        return;
      }
      const courseIds = (this.selectedCourseIds || '').split(',').map(
        (i) => +i,
      ).filter(
        (i) => !!i,
      ).sort();
      const courseSelectedOptions = this.options.filter((op) => {
        const ids = (op.course_ids || '').split(',').map(
          (i) => +i,
        ).filter(
          (i) => !!i,
        ).sort();
        return ids.every((i) => courseIds.includes(i));
      });
      this.selectedId = this.mode === 'multiple' ? courseSelectedOptions.map(
        (i) => i.id,
      ) : courseSelectedOptions[0].id;
      this.getFilteredOptions();
    },

    select() {
      if (!this.options || !this.options.length) {
        return;
      }

      const items = this.options.filter((op) => this.ids.includes(+op.id));
      this.$emit('change', this.ids.join(','), items);
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 420px;
}

.option-title {
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}
</style>
