<template>
  <div class="ss-statistics-topic-list">
    <CommonTitle :titles="titles"></CommonTitle>

    <div class="row row-left row-wrap">
      <div v-if="startTime && endTime" class="col col-left">
        时间范围：{{ startTime }} 至 {{ endTime }}
      </div>

      <div v-if="post" class="col col-left">
        岗位：
        <div>
          <div v-for="(item, idx) of jobNames" :key="idx">{{ item }}</div>
        </div>
      </div>

      <!--      <div v-if="bigAreaId" class="col col-left">-->
      <!--        大区：-->
      <!--        <div>-->
      <!--          <div v-for="(item, idx) of bigAreaNames" :key="idx">{{ item }}</div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div v-if="smallAreaId" class="col col-left">
        小区：
        <div>
          <div v-for="(item, idx) of smallAreaNames" :key="idx">{{ item }}</div>
        </div>
      </div>

      <div v-if="org" class="col col-left">
        经销商：
        <div>
          <div v-for="(item, idx) of orgNames" :key="idx">{{ item }}</div>
        </div>
      </div>

      <div v-if="skillType" class="col col-left">
        模式：{{ skillTypeName }}
      </div>
    </div>

    <div class="row row-right">
      <div class="col col-right">
        <a-button type="primary" :loading="downloading" @click="exportExcel">导出</a-button>
        <a-button @click="back">返回</a-button>
      </div>
    </div>

    <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
             row-key="rowKey" class="table small-cell-table">

      <template slot="participant_percentage">
        <span>参与率</span>
      </template>
      <template slot="man_pass_percentage">
        <span>人员通过率</span>
      </template>

      <template slot="participant_percentage" slot-scope="t, record">{{ record.participant_percentage }}</template>
      <template slot="man_pass_percentage" slot-scope="t, record">{{ record.man_pass_percentage }}</template>
      <template slot="pass_percentage" slot-scope="t, record">{{ record.pass_percentage }}</template>
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Common from '@/utils/Common';
import { getTopicDetailData, getTopicDetailDataDownload } from '@/api/statistics/Index';

import CommonTitle from '@components/CommonTitle';
import { getPagination } from '@components/Pagination';


const columns = [
  {
    width: '',
    align: 'center',
    title: '话术题干',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '',
    align: 'center',
    // title: '参与率',
    dataIndex: 'participant_percentage',
    key: 'participant_percentage',
    slots: { title: 'participant_percentage' },
    scopedSlots: { customRender: 'participant_percentage' },
  },
  {
    width: '',
    align: 'center',
    // title: '人员通过率',
    dataIndex: 'man_pass_percentage',
    key: 'man_pass_percentage',
    slots: { title: 'man_pass_percentage' },
    scopedSlots: { customRender: 'man_pass_percentage' },
  },
  {
    width: '',
    align: 'center',
    title: '人次通过率',
    dataIndex: 'pass_percentage',
    key: 'pass_percentage',
    scopedSlots: { customRender: 'pass_percentage' },
  },
  {
    width: '',
    align: 'center',
    title: '人均时长(秒)',
    dataIndex: 'man_avg_time',
    key: 'man_avg_time',
  },
  {
    width: '',
    align: 'center',
    title: '次均时长(秒)',
    dataIndex: 'avg_time',
    key: 'avg_time',
  },
  {
    width: '',
    align: 'center',
    title: '参与人数',
    dataIndex: 'participant_pnum',
    key: 'participant_pnum',
  },
  {
    width: '',
    align: 'center',
    title: '应参与人数',
    dataIndex: 'people_num',
    key: 'people_num',
  },
  {
    width: '',
    align: 'center',
    title: '未参与人数',
    dataIndex: 'no_participant_num',
    key: 'no_participant_num',
  },
  {
    width: '',
    align: 'center',
    title: '通过人数',
    dataIndex: 'pass_pnum',
    key: 'pass_pnum',
  },
  {
    width: '',
    align: 'center',
    title: '未通过人数',
    dataIndex: 'no_pass_pnum',
    key: 'no_pass_pnum',
  },
  {
    width: '',
    align: 'center',
    title: '参与次数',
    dataIndex: 'participant_num',
    key: 'participant_num',
  },
  {
    width: '',
    align: 'center',
    title: '通过次数',
    dataIndex: 'pass_num',
    key: 'pass_num',
  },
  {
    width: '',
    align: 'center',
    title: '未通过次数',
    dataIndex: 'no_pass_num',
    key: 'no_pass_num',
  },
];


export default {
  name: 'StatisticsSkillDetail',
  components: { CommonTitle },
  computed: {
    ...mapState({
      orgTree: state => state.Common.orgTree,
      jobList: state => state.Common.jobList,
    }),

    jobNames() {
      const ids = this.post && this.post.length ? this.post.split(',').map(
        (i) => +i
      ).filter((i) => !!i) : [];
      return this.jobList.filter(i => ids.includes(i.id)).map(i => i.name);
    },
    bigAreaNames() {
      const ids = this.bigAreaId && this.bigAreaId.length ? this.bigAreaId.split(',').map(i => +i) : [];
      const items = Common.getSelectedItems(ids, this.orgTree);
      return items.map(i => i.name);
    },
    smallAreaNames() {
      const ids = this.smallAreaId && this.smallAreaId.length ? this.smallAreaId.split(',').map(i => +i) : [];
      const items = Common.getSelectedItems(ids, this.orgTree);
      return items.map(i => i.name);
    },
    orgNames() {
      const ids = this.org && this.org.length ? this.org.split(',').map(i => +i) : [];
      const items = Common.getSelectedItems(ids, this.orgTree);
      return items.map(i => i.name);
    },
  },
  data() {
    return {
      titles: [{ title: '话术管理' }, { title: '数据统计' }, { title: '话术查询' }],
      loading: false,
      downloading: false,
      data: [],
      columns: columns,

      pagination: { ...getPagination(), showSizeChanger: false },

      searchType: '',
      id: '',
      bigAreaId: '',
      smallAreaId: '',
      org: '',
      post: '',
      startTime: '',
      endTime: '',
      skillType: '',
      skillTypeName: '',
      isMakeUpExam: '',
    };
  },
  created() {
    const query = this.$route?.query || {};

    this.searchType = query.type;
    this.id = query.id;
    this.bigAreaId = query.bid;
    this.smallAreaId = query.sid;
    this.org = query.org;
    this.post = query.post;
    this.startTime = query.stime;
    this.endTime = query.etime;
    this.skillType = query.skillType;
    this.isMakeUpExam = query.isMakeUpExam;

    switch (+this.skillType) {
      case 1:
        this.skillTypeName = '学习';
        break;
      case 2:
        this.skillTypeName = '练习';
        break;
      case 3:
        this.skillTypeName = '考试';
        break;
    }

    this.onSearch();
  },
  destroyed() {
  },
  methods: {
    back() {
      this.$router.back();
    },

    exportExcel() {
      const params = this.getParams(this.pagination.current, this.pagination.pageSize, true);

      this.downloading = true;
      getTopicDetailDataDownload(params).then(() => this.downloading = false).catch(() => this.downloading = false);
    },

    onReset() {
      this.data = [];
      this.pagination = { ...getPagination(), showSizeChanger: false };
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      const data = await getTopicDetailData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      result.forEach((item, idx) => {
        item.rowKey = idx + 1 + (page - 1) * pageSize;
        item.rowKey = 'area-' + item.rowKey;
      });
      this.data = result;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, isDown) {
      const params = {
        page: page,
        per_page: pageSize,
        skill_ids: this.id,
      };
      this.startTime && (params.start_time = this.startTime);
      this.endTime && (params.end_time = this.endTime);
      this.skillType && (params.speech_type = this.skillType);
      (this.org || this.smallAreaId) && (params.org_ids = this.org || this.smallAreaId);
      this.post && (params.post_ids = this.post);

      isDown && (params.down = 1);

      return params;
    },
  },
};
</script>

<style scoped lang="scss">
.ss-statistics-topic-list {

}
</style>
