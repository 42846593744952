<template>
  <a-tooltip :overlay-class-name="overlayClazz" :placement="placement" :title="title">
    <slot></slot>
    <a-icon v-if="!hideIcon" type="info-circle" class="app-custom-tooltip-icon"/>
  </a-tooltip>
</template>

<script>

const placements = ['top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom'];

export default {
  name: 'Tooltip',
  props: {
    hideIcon: {type: Boolean, default: false},
    placement: {type: String, default: 'top', validate: (i) => placements.includes(i)},
    title: {type: String, default: ''},
    theme: {type: String, default: 'dark', validate: (i) => ['light', 'dark'].includes(i)},
  },
  computed: {
    overlayClazz() {
      return 'app-custom-tooltip ' + this.theme;
    },
  },
}
</script>

<style lang="scss">
.app-custom-tooltip {
  &.light .ant-tooltip-inner, &.light .ant-tooltip-arrow::before {
    color: #333333;
    background-color: rgba(255, 255, 255, 0.95);
  }
}

.app-custom-tooltip-icon {
  margin-left: 3px;
}
</style>
