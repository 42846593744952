<template>
  <div class="select-categorized-skill flex flex-start flex-wrap">
    <div class="flex flex-start no-padding col-fixed-width skill-category-col no-horizontal-padding">
      <SelectSkillCategory :individual="true"
                           :default-selected-ids="defaultSkillCate"
                           @select="selectSkillCategory"/>
    </div>

    <div class="flex flex-start mb-10 select-skill-col">
      <SelectSkill :default-selected-ids="selectedSkillIds"
                   :cate1="cate1"
                   :cate2="cate2"
                   mode="multiple"
                   @select="selectSkill"/>
    </div>
  </div>
</template>

<script>
import SelectSkill from '@/components/speechSkill/SelectSkill';
import SelectSkillCategory from '@/components/speechSkill/SelectSkillCategory';

export default {
  name: 'SelectCategorizedSkill',
  components: {SelectSkill, SelectSkillCategory},
  props: {
    defaultSkillCate: {type: Array, default: () => []},
    defaultSkillIds: {type: String, default: undefined},
  },
  watch: {
    defaultSkillCate(cate) {
      this.cate1 = cate[0] || undefined;
      this.cate2 = cate[1] || undefined;
    },
    defaultSkillIds(ids) {
      this.selectedSkillIds = ids || '';
    },
  },
  data() {
    return {
      cate1: undefined,
      cate2: undefined,
      selectedSkillIds: undefined,
      selectedSkills: undefined,
    };
  },
  created() {
    this.selectedSkillIds = this.defaultSkillIds || '';
    this.cate1 = this.defaultSkillCate[0];
    this.cate2 = this.defaultSkillCate[1];
  },
  methods: {
    selectSkillCategory(event) {
      this.cate1 = event?.cate1?.id || undefined;
      this.cate2 = event?.cate2?.id || undefined;
      this.selectedSkillIds = '';
      this.selectedSkills = [];

      this.select();
    },

    selectSkill(list) {
      this.selectedSkillIds = list && list.length ? list.map(i => i.id).join(',') : '';
      this.selectedSkills = list || [];

      const postIds = Array.from(
        new Set(
          (
            this.selectedSkills || []
          ).map(
            (i) => i.post_ids_str || '',
          ).filter(
            (i) => !!i,
          ).join(',').split(','),
        ),
      ).join(',');

      this.select(postIds);
    },

    select(postIds) {
      this.$emit('select', {
        skillCate1: this.cate1,
        skillCate2: this.cate2,
        skills: this.selectedSkills,
        skillIds: this.selectedSkillIds,
        skill: this.selectedSkillIds,
        post_ids: postIds || '',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.select-categorized-skill {

  .skill-category-col {
    width: 800px;
  }
}
</style>
