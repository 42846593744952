<template>
  <div class="ss-statistics-skill-distributor">
    <SkillQuery :exam-pass-status-enabled="false"
                :filter-enabled="false"
                :big-area-enabled="false"
                :job-enabled="jobDataEnabled"
                :cancel-enabled="jobDataEnabled"
                :show-select-all-org="true"
                :show-shop-type="true"
                :downloading="downloading"
                @reset="onReset"
                @search="onSearch"
                @export="exportExcel"
                @cancel="hideJobData"/>

<!--    <div v-if="diffOrg || diffJob" class="row row-left">-->
<!--      <div class="col col-left">-->
<!--        <span v-if="diffOrg">未查询到数据的组织机构：{{ diffOrg }}</span>-->
<!--        <span v-if="diffJob">未查询到数据的岗位：{{ diffJob }}</span>-->
<!--      </div>-->
<!--    </div>-->

    <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
             row-key="rowKey" class="table small-cell-table">
      <Tooltip slot="participant_percentage" title="参与率 = 参与人数 / 应参与人数">
        <span>参与率</span>
      </Tooltip>
      <Tooltip slot="man_pass_percentage" title="人员通过率 = 通过人数 / 参与人数">
        <span>人员通过率</span>
      </Tooltip>
      <Tooltip slot="pass_percentage" title="人次通过率 = 通过人数 / 参与次数">
        <span>人次通过率</span>
      </Tooltip>

      <template slot="participant_percentage" slot-scope="t, r">{{r.participant_percentage}}</template>
      <template slot="man_pass_percentage" slot-scope="t, r">{{r.man_pass_percentage}}</template>
      <template slot="pass_percentage" slot-scope="t, r">{{r.pass_percentage}}</template>

      <template slot="action" slot-scope="text, record">
        <div class="table-cell-wrapper">
          <a-button v-if="jobDataEnabled" type="link" size="small" @click="viewStudentData(record)">查询学员数据</a-button>
          <a-button v-else type="link" size="small" @click="showJobData(record)">查询岗位数据</a-button>
          <a-button type="link" size="small" @click="viewTopicDetail(record)">查询题目明细</a-button>
        </div>
      </template>
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Common from '@/utils/Common';

import {
  getSkillDistributorData,
  getSkillDistributorDataDownload,
  getSkillDistributorPostData,
  getSkillDistributorPostDataDownload,
} from '@/api/statistics/Index';

import Pagination, {getPagination} from '@components/Pagination';
import SkillQuery from '../../components/SkillQuery';
import Tooltip from '@components/Tooltip';
import {statisticsSkillDetail, statisticsSkillTopicList} from '@/utils/Navs/StatisticsNavs';


const columns = [
  {
    width: '',
    align: 'center',
    dataIndex: 'regional_org_name',
    key: 'regional_org_name',
    title: '大区名称',
  },
  {
    width: '',
    align: 'center',
    title: '小区名称',
    dataIndex: 'small_regional_org_name',
    key: 'small_regional_org_name',
  },
  {
    width: '',
    align: 'center',
    title: '经销商名称',
    dataIndex: 'org_name',
    key: 'org_name',
  },
  {
    isJob: true,
    width: '',
    align: 'center',
    title: '岗位名称',
    dataIndex: 'post_name',
    key: 'post_name',
  },
  {
    width: '',
    align: 'center',
    // title: '参与率',
    dataIndex: 'participant_percentage',
    key: 'participant_percentage',
    slots: {title: 'participant_percentage'},
    scopedSlots: {customRender: 'participant_percentage'},
  },
  {
    width: '',
    align: 'center',
    // title: '人员通过率',
    dataIndex: 'man_pass_percentage',
    key: 'man_pass_percentage',
    slots: {title: 'man_pass_percentage'},
    scopedSlots: {customRender: 'man_pass_percentage'},
  },
  {
    width: '',
    align: 'center',
    // title: '人次通过率',
    dataIndex: 'pass_percentage',
    key: 'pass_percentage',
    slots: {title: 'pass_percentage'},
    scopedSlots: {customRender: 'pass_percentage'},
  },
  {
    width: '',
    align: 'center',
    title: '人均时长(秒)',
    dataIndex: 'man_avg_time',
    key: 'man_avg_time',
  },
  {
    width: '',
    align: 'center',
    title: '次均时长(秒)',
    dataIndex: 'avg_time',
    key: 'avg_time',
  },
  {
    width: '',
    align: 'center',
    title: '参与人数',
    dataIndex: 'participant_pnum',
    key: 'participant_pnum',
  },
  {
    width: '',
    align: 'center',
    title: '应参与人数',
    dataIndex: 'people_num',
    key: 'people_num',
  },
  {
    width: '',
    align: 'center',
    title: '未参与人数',
    dataIndex: 'no_participant_num',
    key: 'no_participant_num',
  },
  {
    width: '',
    align: 'center',
    title: '通过人数',
    dataIndex: 'pass_pnum',
    key: 'pass_pnum',
  },
  {
    width: '',
    align: 'center',
    title: '未通过人数',
    dataIndex: 'no_pass_pnum',
    key: 'no_pass_pnum',
  },
  {
    width: '',
    align: 'center',
    title: '参与次数',
    dataIndex: 'participant_num',
    key: 'participant_num',
  },
  {
    width: '',
    align: 'center',
    title: '通过次数',
    dataIndex: 'pass_num',
    key: 'pass_num',
  },
  {
    width: '',
    align: 'center',
    title: '未通过次数',
    dataIndex: 'no_pass_num',
    key: 'no_pass_num',
  },
  {
    width: '',
    align: 'center',
    title: '累计时长(秒)',
    dataIndex: 'sum_use_times',
    key: 'sum_use_times',
  },
  {
    width: '',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];


export default {
  name: 'StatisticsSkillDistributor',
  components: {Pagination, SkillQuery, Tooltip},
  computed: {
    ...mapState({
      queries: state => state.Statistics.speechSkillQuery,
    }),
    columns() {
      return columns.filter(column => !column.isJob || this.jobDataEnabled) || [];
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      data: [],

      pagination: {...getPagination(), showSizeChanger: false, showTotal: () => ''},

      jobDataEnabled: false,

      diffOrg: '',
      diffJob: '',
    };
  },
  created() {
    if (this.queries?.skill && this.queries?.org) {
      this.onSearch();
    }
  },
  destroyed() {
  },
  methods: {
    exportExcel() {
      if (!this.queries?.skill) {
        return this.$message.info({content: '请选择话术'});
      }
      if (!this.queries?.org) {
        return this.$message.info({content: '请选择组织机构'});
      }

      const params = this.getParams(this.pagination.current, this.pagination.pageSize, true);
      this.downloading = true;
      if (this.jobDataEnabled) {
        getSkillDistributorPostDataDownload(params).then(() => this.downloading = false).catch(() => this.downloading = false);
      } else {
        getSkillDistributorDataDownload(params).then(() => this.downloading = false).catch(() => this.downloading = false);
      }
    },

    onReset() {
      this.data = [];
      this.pagination = { ...getPagination(), showSizeChanger: false, showTotal: () => '' };
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (!this.queries?.org) {
        return this.$message.info({content: '请选择组织机构'});
      }

      if (this.jobDataEnabled) {
        this.getPostData(page, pageSize);
      } else {
        this.getDistributeData(page, pageSize);
      }
    },
    async getDistributeData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (!this.queries?.skill) {
        return this.$message.info({content: '请选择话术'});
      }
      if (!this.queries?.org) {
        return this.$message.info({content: '请选择组织机构'});
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      const data = await getSkillDistributorData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      result.forEach((item, idx) => {
        item.rowKey = idx + 1 + (page - 1) * pageSize;
        item.rowKey = 'area-' + item.rowKey;
      });
      this.data = result;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);

      this.diffOrg = data?.meta?.diff_org?.join('，') || '';
      this.diffJob = data?.meta?.diff_post?.join('，') || '';
    },
    async getPostData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (!this.queries?.skill) {
        return this.$message.info({content: '请选择话术'});
      }
      if (!this.queries?.org) {
        return this.$message.info({content: '请选择组织机构'});
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      const data = await getSkillDistributorPostData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      result.forEach((item, idx) => {
        item.rowKey = idx + 1 + (page - 1) * pageSize;
        item.rowKey = 'post-' + item.rowKey;
      });
      this.data = result;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);

      this.diffOrg = data?.meta?.diff_org?.join('，') || '';
      this.diffJob = data?.meta?.diff_post?.join('，') || '';
    },
    getParams(page, pageSize, isDown) {
      const params = {
        page: page,
        per_page: pageSize,
      };

      this.queries?.startDateTime && (params.start_time = this.queries.startDateTime);
      this.queries?.endDateTime && (params.end_time = this.queries.endDateTime);
      this.queries?.skillType && (params.speech_type = this.queries.skillType);
      this.queries?.skill && (params.skill_ids = this.queries.skill);
      this.queries?.org && (params.regional_org_ids = this.queries.org);
      this.jobDataEnabled && this.queries?.job && (params.post_ids = this.queries.job);
      if (this.queries?.shopType) {
        params.store_state = this.queries.shopType.store_state || '';
        params.store_type = this.queries.shopType.store_type || '';
        params.operation_state = this.queries.shopType.operation_state || '';
        params.store_age = this.queries.shopType.store_age || '';
      }
      isDown && (params.down = 1);

      return params;
    },

    // 查询岗位数据
    showJobData(event) {
      const job = (event?.post_id || '') + '';
      job && this.setSpeechSkillQuery({job: job});
      this.jobDataEnabled = true;
      this.onSearch();
    },
    hideJobData() {
      this.jobDataEnabled = false;
      this.onSearch();
    },

    // 查询学员数据
    viewStudentData() {
      this.$router.replace(statisticsSkillDetail[2].path);
    },
    // 查询题目明细
    viewTopicDetail(record) {
      const query = {type: 1};
      this.queries?.startDateTime && (query.stime = this.queries.startDateTime);
      this.queries?.endDateTime && (query.etime = this.queries.endDateTime);
      this.queries?.skillType && (query.skillType = this.queries.skillType);
      this.queries?.skill && (query.id = this.queries.skill);

      record.regional_org_id && (query.bid = record.regional_org_id);
      record.small_regional_org_id && (query.sid = record.small_regional_org_id);
      record.org_id && (query.org = record.org_id);
      record.post_id && (query.post = record.post_id);

      this.$router.push({
        path: statisticsSkillTopicList[0].path,
        query,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ss-statistics-skill-distributor {
  width: 100%;

  .table-cell-wrapper {
    width: 90px;
  }
}
</style>
