<template>
  <div class="statistic-video-result">
    <a-modal :title="modalTitle"
             :visible="modalShown"
             :confirm-loading="confirmLoading"
             :width="800"
             :centered="true"
             :mask="true"
             :mask-closable="true"
             :destroy-on-close="true"
             :after-close="emitClose"
             @cancel="close"
             dialog-class="statistic-video-result-modal">
      <div class="statistic-video-result-box">
        <div v-if="url" :id="containerId" class="statistic-video-box">
          <video :src="url" class="video" :id="playerId"></video>
          <PlayerControlBar :video-container-id="containerId" :video-id="playerId"
                            class="control-bar"></PlayerControlBar>
        </div>
        <span v-else>暂无视频</span>
      </div>

      <div slot="footer">
        <a-button @click="close">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Common from '@/utils/Common';

import PlayerControlBar from '@views/statistics/components/PlayerControlBar';

export default {
  name: 'StatisticVideoResult',
  components: {PlayerControlBar},
  props: {
    data: {type: Object, default: () => new Object()},
  },
  computed: {
    modalTitle() {
      return '查看视频';
    },
    url() {
      return this.data?.exam_video_url || '';
    },
  },

  data() {
    return {
      playerId: 'P' + Common.getRandomString(32),
      containerId: 'C' + Common.getRandomString(32),

      modalShown: true,
      confirmLoading: false,
    }
  },

  methods: {
    close() {
      this.modalShown = false;
    },
    emitClose() {
      this.$emit('close');
    },
  },
}
</script>

<style lang="scss">
.statistic-video-result-modal {

  .ant-modal-body {
    padding: 3px;
  }

  .statistic-video-result-box {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 120px;
    max-height: 80vh;

    .statistic-video-box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #000000;

      &.fullscreen .video {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .video {
      width: 100%;
      height: 100%;
      max-width: 80vw;
      max-height: 80vh;
    }

    .control-bar {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
