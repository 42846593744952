<template>
  <div class="ss-statistics-group-distributor">
    <GroupQuery :big-area-enabled="false"
                :record-enabled="false"
                :job-enabled="jobDataEnabled"
                :cancel-enabled="jobDataEnabled"
                :show-select-all-org="true"
                :show-shop-type="true"
                :downloading="downloading"
                @reset="onReset"
                @search="onSearch"
                @export="exportExcel"
                @cancel="hideJobData"/>


    <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
             row-key="rowKey" class="table small-cell-table">

      <Tooltip slot="participant_percentage" title="参与率 = 参与人数 / 应参与人数">
        <span>{{ groupName ? (groupName + ' - ') : '' }}参与率</span>
      </Tooltip>
      <Tooltip slot="man_pass_percentage" title="人员通过率 = 通过人数 / 参与人数">
        <span>{{ groupName ? (groupName + ' - ') : '' }}人员通过率</span>
      </Tooltip>
      <Tooltip slot="pass_percentage" title="人次通过率 = 通过人数 / 参与次数">
        <span>{{ groupName ? (groupName + ' - ') : '' }}人次通过率</span>
      </Tooltip>
      <Tooltip slot="man_avg_time" title="人均时长 = 累计时长 / 参与人数">
        <span>{{ groupName ? (groupName + ' - ') : '' }}人均时长(秒)</span>
      </Tooltip>
      <Tooltip slot="avg_time" title="次均时长 = 累计时长 / 参与次数">
        <span>{{ groupName ? (groupName + ' - ') : '' }}次均时长(秒)</span>
      </Tooltip>

      <template slot="participant_percentage" slot-scope="t, r">{{ r.participant_percentage }}</template>
      <template slot="man_pass_percentage" slot-scope="t, r">{{ r.man_pass_percentage }}</template>
      <template slot="pass_percentage" slot-scope="t, r">{{ r.pass_percentage }}</template>

      <template slot="action" slot-scope="text, record">
        <div class="table-cell-wrapper">
          <a-button type="link" size="small" v-if="!jobDataEnabled" @click="showJobData(record)">查询岗位数据</a-button>
          <!--          <a-button type="link" size="small" v-if="!jobDataEnabled" @click="viewTopicDetail(record)">查询题目明细</a-button>-->
          <a-button type="link" size="small" v-if="jobDataEnabled" @click="viewStudentData(record)">查询学员数据</a-button>
        </div>
      </template>
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Common from '@/utils/Common';
import { statisticsGroupDetail } from '@/utils/Navs/StatisticsNavs';
import {
  getGroupDistributorData,
  getGroupDistributorDataDownload,
  getGroupDistributorPostData,
  getGroupDistributorPostDataDownload,
} from '@/api/statistics/Index';

import GroupQuery from '@views/statistics/components/GroupQuery';
import Pagination, { getPagination } from '@components/Pagination';
import Tooltip from '@components/Tooltip';

const columns = [
  {
    width: '',
    align: 'center',
    dataIndex: 'regional_org_name',
    key: 'regional_org_name',
    title: '大区名称',
  },
  {
    width: '',
    align: 'center',
    title: '小区名称',
    dataIndex: 'small_regional_org_name',
    key: 'small_regional_org_name',
  },
  {
    width: '',
    align: 'center',
    title: '经销商名称',
    dataIndex: 'org_name',
    key: 'org_name',
  },
  {
    isJob: true,
    width: '',
    align: 'center',
    title: '岗位名称',
    dataIndex: 'post_name',
    key: 'post_name',
  },
  {
    width: '',
    align: 'center',
    // title: '参与率',
    dataIndex: 'participant_percentage',
    key: 'participant_percentage',
    slots: { title: 'participant_percentage' },
    scopedSlots: { customRender: 'participant_percentage' },
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    // title: '人员通过率',
    dataIndex: 'man_pass_percentage',
    key: 'man_pass_percentage',
    slots: { title: 'man_pass_percentage' },
    scopedSlots: { customRender: 'man_pass_percentage' },
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    // title: '人次通过率',
    dataIndex: 'pass_percentage',
    key: 'pass_percentage',
    slots: { title: 'pass_percentage' },
    scopedSlots: { customRender: 'pass_percentage' },
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    // title: '人均时长',
    dataIndex: 'man_avg_time',
    key: 'man_avg_time',
    slots: { title: 'man_avg_time' },
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    // title: '次均时长',
    dataIndex: 'avg_time',
    key: 'avg_time',
    slots: { title: 'avg_time' },
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '参与人数',
    dataIndex: 'participant_pnum',
    key: 'participant_pnum',
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '应参与人数',
    dataIndex: 'people_num',
    key: 'people_num',
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '未参与人数',
    dataIndex: 'no_participant_num',
    key: 'no_participant_num',
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '通过人数',
    dataIndex: 'pass_pnum',
    key: 'pass_pnum',
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '未通过人数',
    dataIndex: 'no_pass_pnum',
    key: 'no_pass_pnum',
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '参与次数',
    dataIndex: 'participant_num',
    key: 'participant_num',
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '通过次数',
    dataIndex: 'pass_num',
    key: 'pass_num',
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '未通过次数',
    dataIndex: 'no_pass_num',
    key: 'no_pass_num',
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '累计时长(秒)',
    dataIndex: 'sum_use_times',
    key: 'sum_use_times',
    prefix: true,
  },
  {
    width: '',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'StatisticsGroupDistributor',
  components: { GroupQuery, Pagination, Tooltip },
  computed: {
    ...mapState({
      queries: state => state.Statistics.speechGroupQuery,
    }),
    columns() {
      return columns.filter(column => !column.isJob || this.jobDataEnabled).map((i) => {
        const item = { ...i };
        if (item.title && item.prefix && this.groupName) {
          item.title = this.groupName + ' - ' + item.title;
        }
        return item;
      });
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      data: [],

      pagination: { ...getPagination(), showSizeChanger: false },

      jobDataEnabled: false,
      groupName: '',
    };
  },
  created() {
    const org = this.$route?.query.org;

    if (org) {
      this.setSpeechGroupQuery({ org: org });
    }

    if (this.queries?.group) {
      this.onSearch();
    }
  },
  destroyed() {
  },
  methods: {
    ...mapMutations({
      setSpeechGroupQuery(commit, query) {
        commit('Statistics/setSpeechGroupQuery', query);
      },
    }),

    exportExcel() {
      const params = this.getParams(this.pagination.current, this.pagination.pageSize, true);

      if (!this.queries?.group) {
        return this.$message.info({ content: '请选择话术检核' });
      }

      this.downloading = true;
      if (this.jobDataEnabled) {
        getGroupDistributorPostDataDownload(params).then(() => this.downloading = false).catch(() => this.downloading = false);
      } else {
        getGroupDistributorDataDownload(params).then(() => this.downloading = false).catch(() => this.downloading = false);
      }
    },

    onReset() {
      this.data = [];
      this.groupName = '';
      this.pagination = { ...getPagination(), showSizeChanger: false };
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      if (this.jobDataEnabled) {
        this.getPostData(page, pageSize);
      } else {
        this.getAreaData(page, pageSize);
      }
    },
    async getAreaData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (!this.queries?.group) {
        return this.$message.info({ content: '请选择话术检核' });
      }
      if (!this.queries?.org) {
        return this.$message.info({ content: '请选择组织机构' });
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      const data = await getGroupDistributorData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.groupName = this.queries.groupName || '';
      const result = data?.data || [];
      result.forEach((item, idx) => {
        item.rowKey = idx + 1 + (page - 1) * pageSize;
        item.rowKey = 'dis-' + item.rowKey;
      });
      this.data = result;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    async getPostData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (!this.queries?.group) {
        return this.$message.info({ content: '请选择话术检核' });
      }
      if (!this.queries?.org) {
        return this.$message.info({ content: '请选择组织机构' });
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      const data = await getGroupDistributorPostData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      result.forEach((item, idx) => {
        item.rowKey = idx + 1 + (page - 1) * pageSize;
        item.rowKey = 'post-' + item.rowKey;
      });
      this.data = result;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, isDown) {
      const params = {
        page: page,
        per_page: pageSize,
      };

      this.queries?.startDateTime && (params.start_time = this.queries.startDateTime);
      this.queries?.endDateTime && (params.end_time = this.queries.endDateTime);
      this.queries?.skillType && (params.speech_type = this.queries.skillType);
      this.queries?.group && (params.check_id = this.queries.group);
      this.queries?.org && (params.regional_org_ids = this.queries?.org);
      this.queries?.examType && (params.is_makeup_exam = (this.queries?.examType === 1 ? 0 : 1));
      this.jobDataEnabled && this.queries?.job && (params.post_ids = this.queries.job);
      if (this.queries?.shopType) {
        params.store_state = this.queries.shopType.store_state || '';
        params.store_type = this.queries.shopType.store_type || '';
        params.operation_state = this.queries.shopType.operation_state || '';
        params.store_age = this.queries.shopType.store_age || '';
      }
      isDown && (params.down = 1);

      return params;
    },

    // 查询岗位数据
    showJobData(event) {
      const bigArea = (event?.regional_org_id || '') + '';
      bigArea && this.setSpeechGroupQuery({ bigArea: bigArea });
      this.jobDataEnabled = true;
      this.onSearch();
    },
    hideJobData() {
      this.jobDataEnabled = false;
      this.onSearch();
    },

    // 查询学员数据
    viewStudentData(record) {
      const bigArea = (record?.regional_org_id || '') + '';
      const job = (record?.post_id || '') + '';
      bigArea && this.setSpeechGroupQuery({ bigArea: bigArea, job: job });
      this.$router.replace(statisticsGroupDetail[2].path);
    },
    // 查询题目明细
    // viewTopicDetail(record) {
    //   const query = {type: 2};
    //   this.queries?.startDateTime && (query.stime = this.queries.startDateTime);
    //   this.queries?.endDateTime && (query.etime = this.queries.endDateTime);
    //   this.queries?.group && (query.id = this.queries.group);
    //   this.queries?.examType === 2 ? (query.isMakeUpExam = 1) : '';
    //
    //   record.regional_org_id && (query.bid = record.regional_org_id);
    //   record.small_regional_org_id && (query.sid = record.small_regional_org_id);
    //   record.org_id && (query.org = record.org_id);
    //   record.post_id && (query.post = record.post_id);
    //
    //   this.$router.push({
    //     path: statisticsSkillTopicList[0].path,
    //     query,
    //   });
    // },
  },
};
</script>

<style scoped lang="scss">
.ss-statistics-group-distributor {
  width: 100%;
}
</style>
